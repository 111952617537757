import { ChartDateType, EnergyType } from "api";
import { createSafeContext } from "utils/createSafeContext";

type FiltersContextType = {
  date: Record<ChartDateType, Date>;
  setDate: React.Dispatch<React.SetStateAction<Record<ChartDateType, Date>>>;
  dateType: ChartDateType[];
  setDateType: React.Dispatch<React.SetStateAction<ChartDateType[]>>;
  energyType: EnergyType[];
  setEnergyType: React.Dispatch<React.SetStateAction<EnergyType[]>>;
  brush: {
    startIndex?: number;
    endIndex?: number;
  };
  setBrush: React.Dispatch<
    React.SetStateAction<{
      startIndex?: number;
      endIndex?: number;
    }>
  >;
};

export const [FiltersContext, useFiltersContext] =
  createSafeContext<FiltersContextType>("FiltersContext");
