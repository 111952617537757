import { Grid2, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import { useMemo } from "react";
import SunCalc from "suncalc";

import { Widget } from "components/elements/mui";
import { MessageKey, useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

export const DaylightInfo = () => {
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const sunInfo = useMemo(
    () =>
      selectedPowerPlant
        ? SunCalc.getTimes(
            new Date(),
            +selectedPowerPlant.lat,
            +selectedPowerPlant.lng,
          )
        : undefined,
    [selectedPowerPlant],
  );

  if (!sunInfo) return null;

  return (
    <Widget
      size={{
        xs: 12,
      }}
      styles={{ cardContent: { padding: 0, paddingBottom: "0 !important" } }}
      content={
        <Grid2 container size={12} spacing={1} justifyContent={"space-between"}>
          <Grid2
            container
            direction={"column"}
            spacing={0}
            justifyContent="flex-end"
            width={"100%"}
          >
            {(
              [
                {
                  name: "weather.firstLight",
                  value: format(new Date(sunInfo.dawn), "HH:mm"),
                },
                {
                  name: "weather.sunrise",
                  value: format(new Date(sunInfo.sunrise), "HH:mm"),
                },
                {
                  name: "weather.sunset",
                  value: format(new Date(sunInfo.sunset), "HH:mm"),
                },
                {
                  name: "weather.lastLight",
                  value: format(new Date(sunInfo.dusk), "HH:mm"),
                },
                {
                  name: "weather.totalDaylight",
                  value: `${format(
                    new Date(sunInfo.sunset).getTime() -
                      new Date(sunInfo.sunrise).getTime(),
                    "HH",
                  )}h ${format(
                    new Date(sunInfo.sunset).getTime() -
                      new Date(sunInfo.sunrise).getTime(),
                    "mm",
                  )}min`,
                },
              ] satisfies { name: MessageKey; value: string }[]
            ).map(({ name, value }) => (
              <Grid2
                container
                spacing={1}
                sx={{
                  padding: "8px",
                  "&:last-child": { borderBottom: "none" },
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  align="right"
                  fontSize={16}
                  fontWeight={500}
                >
                  {formatMessage(name)}
                </Typography>
                <Typography
                  variant="h6"
                  align="right"
                  fontSize={16}
                  fontWeight={400}
                  color={grey[500]}
                >
                  {value}
                </Typography>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
      }
    />
  );
};
