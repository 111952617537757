import { useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useMemo, useState } from "react";
import {
  Area,
  Bar,
  ComposedChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "components/elements/widgets/EnergyChart/styles.scss";

import { ForecastParameter, GetForecastResponsePrepared } from "api";
import { useFiltersContext } from "components/contexts";
import { Widget } from "components/elements/mui";
import { EnergyChartTooltip } from "components/elements/widgets/EnergyChart/components";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

import { ForecastWidgetContent } from "./components";
import { createHelperFunctions, useParamsUtils } from "./utils";

// Energy param shows the predicted energy production for preceiding period (15 minutes / 1 hour)
// that's why we need to substract this period from the date if energy is selected

export const ForecastChart = ({
  data,
}: {
  data: GetForecastResponsePrepared;
}) => {
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const { date } = useFiltersContext();
  const [selectedParam, setSelectedParam] =
    useState<ForecastParameter>("energy");

  const paramsUtils = useParamsUtils();
  const helperFunctions = useMemo(
    () => createHelperFunctions(selectedParam, selectedPowerPlant),
    [selectedParam, selectedPowerPlant],
  );

  const startDate = useMemo(
    () => helperFunctions.start(date["day"]),
    [helperFunctions, date],
  );
  const endDate = useMemo(
    () => helperFunctions.end(date["day"]),
    [helperFunctions, date],
  );

  const preparedData = useMemo(
    () => (data ? helperFunctions.prepareData(data, date["day"]) : undefined),
    [data, helperFunctions, date],
  );

  const noData = useMemo(() => !data || data.minutely15.length === 0, [data]);
  return (
    <>
      <Widget
        title={formatMessage(paramsUtils[selectedParam].title)}
        size={{
          xs: 12,
        }}
        content={
          <ForecastWidgetContent
            selectedParam={selectedParam}
            setSelectedParam={setSelectedParam}
            currentDaily={preparedData?.currentDaily}
            currentHourly={preparedData?.currentMinutely15}
          />
        }
      />
      <Widget
        size={{ xs: 12 }}
        content={
          <ResponsiveContainer height={300} style={{ position: "relative" }}>
            <ComposedChart
              data={preparedData?.minutely15}
              margin={{
                top: 20,
                right: 10,
                bottom: 0,
                left: noData ? -50 : 0,
              }}
            >
              {noData || !preparedData?.minutely15 ? (
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  fill={theme.palette.text.disabled}
                >
                  {formatMessage("chart.noData")}
                </text>
              ) : (
                <>
                  <XAxis
                    dataKey="date"
                    scale={paramsUtils[selectedParam].chartOptions.scaleType}
                    ticks={helperFunctions.generateTicks(date["day"])}
                    tickFormatter={(value) =>
                      helperFunctions.formatXAxis(value)
                    }
                    min={startDate.getTime()}
                    max={endDate.getTime()}
                    tick={{
                      fill: theme.palette.text.primary,
                      fontSize: 12,
                      fontFamily: theme.typography.fontFamily,
                    }}
                    axisLine={{
                      stroke: theme.palette.divider,
                      strokeWidth: 1,
                    }}
                  />
                  <YAxis
                    dataKey={selectedParam}
                    tickFormatter={(value) =>
                      helperFunctions.formatYAxis(value)
                    }
                    tick={{
                      fill: theme.palette.text.primary,
                      fontSize: 12,
                      fontFamily: theme.typography.fontFamily,
                    }}
                    axisLine={{
                      stroke: theme.palette.divider,
                      strokeWidth: 1,
                    }}
                  />
                  <Tooltip
                    content={
                      <EnergyChartTooltip
                        formatLabel={helperFunctions.formatXTooltip}
                        formatValue={helperFunctions.formatYTooltip}
                      />
                    }
                    cursor={{
                      strokeDasharray: "5 5",
                      stroke: theme.palette.divider,
                      strokeWidth: 2,
                    }}
                    position={{ y: 0 }}
                  />
                  <Bar
                    dataKey={selectedParam}
                    fill={theme.palette.secondary.main}
                    activeBar={<Rectangle fill={"#413ea0"} />}
                    hide={
                      paramsUtils[selectedParam].chartOptions.seriesType !==
                      "bar"
                    }
                  />
                  <Area
                    type="monotone"
                    baseValue="dataMin"
                    dataKey={selectedParam}
                    stroke={blue[500]}
                    strokeWidth={2}
                    fill="url(#colorGradient)"
                    hide={
                      paramsUtils[selectedParam].chartOptions.seriesType !==
                      "line"
                    }
                  />
                  <defs>
                    <linearGradient
                      id="colorGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={blue[500]}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="70%"
                        stopColor={blue[500]}
                        stopOpacity={0.3}
                      />
                      <stop
                        offset="95%"
                        stopColor={blue[500]}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        }
      />
    </>
  );
};
