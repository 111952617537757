import { tz } from "@date-fns/tz";
import { Grid2, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  addDays,
  addHours,
  addMilliseconds,
  endOfDay,
  format,
  startOfDay,
  startOfMinute,
  subMinutes,
} from "date-fns";
import { ScaleType } from "recharts/types/util/types";

import {
  DailyPrepared,
  ForecastParameter,
  GetForecastResponsePrepared,
  HelperFunctionsChartDateTypeForecast,
  HourlyPrepared,
  PowerPlant,
} from "api";
import { WidgetContent } from "components/elements/mui";
import { generateTicks } from "components/elements/widgets/EnergyChart/utils";
import { MessageKey, useFormatMessage } from "lang";
import { formatEnergy } from "utils/energy";

export const createHelperFunctions = (
  selectedParam: ForecastParameter,
  powerPlant?: PowerPlant,
): HelperFunctionsChartDateTypeForecast => {
  const timezone = tz(powerPlant?.timezone || "Europe/Warsaw");

  const helpers: HelperFunctionsChartDateTypeForecast = {
    start: (date: Date) =>
      selectedParam === "energy"
        ? addHours(
            startOfDay(date, {
              in: timezone,
            }),
            1,
          )
        : startOfDay(date, {
            in: timezone,
          }),
    end: (date: Date) =>
      selectedParam === "energy"
        ? startOfDay(addDays(date, 1), {
            in: timezone,
          })
        : endOfDay(date, {
            in: timezone,
          }),
    formatXAxis: (date: Date) => format(date, "HH:mm"),
    formatXTooltip: (date: Date, period?: number) =>
      period
        ? `${format(date, "HH:mm")} - ${format(addMilliseconds(date, period), "HH:mm")}`
        : format(date, "HH:mm"),
    formatYAxis: (value: number) =>
      selectedParam === "energy"
        ? formatEnergy(value).formatted
        : `${Math.round(value).toString()} °C`,
    formatYTooltip: (value: number) =>
      selectedParam === "energy"
        ? formatEnergy(value).formatted
        : `${Math.round(value).toString()} °C`,
    formatDateType: (date: Date) => format(date, "P"),
    prepareData: (data: GetForecastResponsePrepared, date: Date) => {
      const minutely15Data = data.minutely15
        .filter(
          (record) =>
            record.date.getTime() >= helpers.start(date).getTime() &&
            record.date.getTime() <= helpers.end(date).getTime(),
        )
        .map((record) =>
          selectedParam === "energy"
            ? {
                ...record,
                date: subMinutes(record.date, 15),
                period: 900000, // 15 minutes
              }
            : {
                ...record,
                date: new Date(record.date),
              },
        );

      return {
        minutely15: minutely15Data,
        currentDaily: data.daily.find(
          (d) =>
            d.date.getTime() ===
            startOfDay(date, {
              in: timezone,
            }).getTime(),
        ),
        currentMinutely15: minutely15Data.find((m) => {
          const roundedNow = startOfMinute(new Date(), {
            in: timezone,
          }).getTime();

          return (
            new Date(m.date).getTime() === roundedNow - (roundedNow % 900000) // 15 minutes
          );
        }),
      };
    },
    generateTicks: (date: Date) =>
      generateTicks(helpers.start(date), helpers.end(date), (date: Date) =>
        addHours(date, 3),
      ),
  };
  return helpers;
};

export const useParamsUtils = (): Record<
  ForecastParameter,
  {
    component: (
      dailyData?: DailyPrepared,
      hourlyData?: HourlyPrepared,
    ) => JSX.Element;
    title: MessageKey;
    label: MessageKey;
    icon: string;
    chartOptions: {
      scaleType: ScaleType;
      seriesType: "line" | "bar";
    };
  }
> => {
  const formatMessage = useFormatMessage();

  return {
    energy: {
      title: "predictedProduction",
      label: "energy",
      icon: "solar_power",
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      component: (dailyData) => {
        const energy = formatEnergy(dailyData?.energy || 0);
        return (
          <WidgetContent
            value={energy.value}
            unit={energy.unit}
            gridProps={{ alignItems: "flex-start", size: { xs: 6 } }}
          />
        );
      },
    },
    temperature: {
      title: "temperature",
      label: "temperature",
      icon: "thermostat",
      chartOptions: {
        scaleType: "auto",
        seriesType: "line",
      },
      component: (dailyData, hourlyData) => {
        const isToday = !hourlyData;
        if (isToday)
          return (
            <Grid2 container direction={"column"} spacing={0}>
              <Grid2 container spacing={1}>
                <Typography
                  align="right"
                  variant="h4"
                  fontSize={{ xs: "clamp(18px, 6vw, 26px)", sm: 26 }}
                  fontWeight={700}
                >
                  {`${dailyData?.temperature.max ? Math.round(dailyData?.temperature.max) : "-"}°`}
                </Typography>
                <Typography
                  align="right"
                  variant="h4"
                  fontSize={{ xs: "clamp(18px, 6vw, 26px)", sm: 26 }}
                  color={grey[700]}
                  fontWeight={700}
                >
                  {`${dailyData?.temperature.min ? Math.round(dailyData?.temperature.min) : "-"}°`}
                </Typography>
              </Grid2>
              <Typography
                variant="h6"
                align="left"
                fontSize={12}
                color={grey[700]}
                fontWeight={700}
              >
                {formatMessage("weather.units.celsius")}
              </Typography>
            </Grid2>
          );

        return (
          <Grid2 container direction={"column"} spacing={0}>
            <Typography
              align="left"
              variant="h4"
              fontSize={{ xs: "clamp(18px, 6vw, 26px)", sm: 26 }}
              fontWeight={700}
            >
              {`${hourlyData?.temperature ? Math.round(hourlyData?.temperature) : "-"}°C`}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              fontSize={12}
              color={grey[700]}
              fontWeight={700}
            >
              {`${formatMessage("weather.temperature.from")} ${dailyData?.temperature.min ? Math.round(dailyData?.temperature.min) : "-"}°C ${formatMessage("weather.temperature.to")} ${dailyData?.temperature.max ? Math.round(dailyData?.temperature.max) : "-"}°C`}
            </Typography>
          </Grid2>
        );
      },
    },
  };
};
