import { tz } from "@date-fns/tz";
import { Grid2, Icon, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format, startOfDay, startOfMinute } from "date-fns";
import { useMemo } from "react";
import SunCalc from "suncalc";

import { GetForecastResponsePrepared } from "api";
import { Widget } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

export const ForecastWeather = ({
  data,
}: {
  data: GetForecastResponsePrepared;
}) => {
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const timezone = tz(selectedPowerPlant?.timezone || "Europe/Warsaw");
  const sunInfo = useMemo(
    () =>
      selectedPowerPlant
        ? SunCalc.getTimes(
            new Date(),
            +selectedPowerPlant.lat,
            +selectedPowerPlant.lng,
          )
        : undefined,
    [selectedPowerPlant],
  );

  const dailyData = data.daily.find(
    (d) =>
      d.date.getTime() ===
      startOfDay(new Date(), {
        in: timezone,
      }).getTime(),
  );
  const minutely15Data = data.minutely15.find((m) => {
    const roundedNow = startOfMinute(new Date(), {
      in: timezone,
    }).getTime();

    return (
      new Date(m.date).getTime() === roundedNow - (roundedNow % 900000) // 15 minutes
    );
  });

  if (!sunInfo) return null;

  return (
    <Widget
      title={formatMessage("weather")}
      size={{
        xs: 6,
      }}
      content={
        <Grid2 container size={12} spacing={1} justifyContent={"space-between"}>
          <Grid2
            container
            direction={"column"}
            spacing={0}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Typography
              align="right"
              variant="h4"
              fontSize={{ xs: "clamp(18px, 6vw, 26px)", sm: 26 }}
              fontWeight={700}
            >
              {`${minutely15Data?.temperature ? Math.round(minutely15Data?.temperature) : "-"}°C`}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              fontSize={12}
              color={grey[700]}
              fontWeight={700}
            >
              {`${formatMessage("weather.temperature.from")} ${dailyData?.temperature.min ? Math.round(dailyData?.temperature.min) : "-"}°C ${formatMessage("weather.temperature.to")} ${dailyData?.temperature.max ? Math.round(dailyData?.temperature.max) : "-"}°C`}
            </Typography>
          </Grid2>
          <Grid2
            container
            direction={"column"}
            spacing={0}
            justifyContent="flex-end"
          >
            {[
              { icon: "wb_twilight", time: sunInfo.sunrise },
              { icon: "wb_sunny", time: sunInfo.solarNoon },
              { icon: "bedtime", time: sunInfo.sunset },
            ].map(({ icon, time }) => (
              <Grid2 container spacing={1} alignItems={"center"}>
                <Icon
                  sx={{
                    color: grey[700],
                    fontSize: 17,
                  }}
                >
                  {icon}
                </Icon>
                <Typography
                  variant="h6"
                  align="right"
                  fontSize={13}
                  color={grey[300]}
                  fontWeight={700}
                >
                  {format(new Date(time), "HH:mm")}
                </Typography>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
      }
    />
  );
};
