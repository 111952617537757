import { tz } from "@date-fns/tz";
import { CircularProgress, Grid2, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format, startOfToday } from "date-fns";

import { useGetForecast } from "api";
import { FiltersContextProvider } from "components/contexts";
import { PageLayout } from "components/layouts";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

import { DaylightInfo, ForecastDateFilters, ForecastChart } from "./components";

export const ForecastPage = () => {
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const FIVE_MINUTES = 5 * 60 * 1000;

  const { data, isLoading } = useGetForecast({
    request: {
      model: "best_match",
      parameter: "global_tilted_irradiance",
      past_days: 61, // 2 months
    },
    options: {
      refetchOnWindowFocus: true,
      staleTime: FIVE_MINUTES,
    },
  });

  if (isLoading || !data)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <FiltersContextProvider
      initialState={{
        date: {
          day: startOfToday({
            in: tz(selectedPowerPlant?.timezone || "Europe/Warsaw"),
          }),
          month: new Date(),
          year: new Date(),
          total: new Date(),
        },
      }}
    >
      <PageLayout pageTitle={formatMessage("pageTitle.forecast")}>
        <Grid2 container spacing={3}>
          <ForecastDateFilters data={data.daily} />
          <ForecastChart data={data} />
          <DaylightInfo />
          <Typography
            variant="h6"
            sx={{
              mx: "auto",
              fontWeight: 500,
              fontSize: 13,
              color: grey[500],
            }}
          >
            {`${formatMessage("lastUpdate")}: ${data?.update ? format(new Date(data?.update), "P HH:mm") : "-"}`}
          </Typography>
        </Grid2>
      </PageLayout>
    </FiltersContextProvider>
  );
};
