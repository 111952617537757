import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { axiosInstance, RECORDS } from "api";
import { getSelectedPowerPlant, useAppSelector } from "store";

import {
  EnergyType,
  energyTypeList,
  GetRecordsRequest,
  GetRecordsResponse,
} from "./types";

export const useGetRecords = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>({
  meterId: meterIdProp,
  options,
  request,
}: {
  meterId?: string;
  request: GetRecordsRequest<SelectedMetrics>;
  options?: Partial<UseQueryOptions<GetRecordsResponse<SelectedMetrics>>>;
}) => {
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const meterId = meterIdProp || selectedPowerPlant?.meterId;
  const metrics = request.metrics || energyTypeList;

  return useQuery<GetRecordsResponse<SelectedMetrics>>({
    queryKey: [
      RECORDS.RECORDS(meterId!),
      meterId,
      JSON.stringify(request),
      metrics,
    ],
    queryFn: async () => {
      const data = await axiosInstance.get<GetRecordsResponse<SelectedMetrics>>(
        RECORDS.RECORDS(meterId!),
        {
          params: { ...request, metrics },
        },
      );
      return data.data;
    },
    enabled: !!meterId,
    ...options,
  });
};
