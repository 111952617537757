import {
  Avatar,
  Icon,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@mui/material";
import Select, { SelectChangeEvent, selectClasses } from "@mui/material/Select";

import { useAppLayoutContext } from "components/contexts";
import {
  getPowerPlants,
  getSelectedPowerPlant,
  setSelectedPowerPlant,
  useAppDispatch,
  useAppSelector,
} from "store";

export const PowerPlantSelector = () => {
  const { setSidebarOpen } = useAppLayoutContext();
  const dispatch = useAppDispatch();

  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const powerPlants = useAppSelector(getPowerPlants);

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    setSidebarOpen(false);

    const selectedPowerPlant = powerPlants.find(
      (powerPlant) => powerPlant.meterId === event.target.value,
    );
    if (selectedPowerPlant)
      dispatch(
        setSelectedPowerPlant({ ...selectedPowerPlant, offline: false }),
      );
  };

  return (
    <Select
      value={selectedPowerPlant?.meterId}
      onChange={handleChange}
      sx={{
        maxHeight: 56,
        m: 4,
        [`& .${selectClasses.select}`]: {
          display: "flex",
          alignItems: "center",
          gap: "2px",
          pl: 1,
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        PaperProps: {
          sx: {
            mt: -0.5,
          },
        },
      }}
    >
      {powerPlants.map((powerPlant) => (
        <MenuItem key={powerPlant.meterId} value={powerPlant.meterId}>
          <ListItemAvatar sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar alt="Power plant">
              <Icon>solar_power</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={powerPlant.name} />
        </MenuItem>
      ))}
    </Select>
  );
};
