import { useState } from "react";

export const useStateAddon = <T,>(
  defaultValue: T,
  addon: (value: T) => void,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [s, setS] = useState<T>(defaultValue);
  const setStateAddon: React.Dispatch<React.SetStateAction<T>> = (
    value: T | ((p: T) => T),
  ) => {
    if (typeof value === "function") addon((value as (p: T) => T)(s));
    else addon(value);
    setS(value);
  };
  return [s, setStateAddon];
};
