import { tz } from "@date-fns/tz";
import { Chip, Grid2, Typography } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { format, isToday } from "date-fns";

import { GetForecastResponsePrepared } from "api";
import { useFiltersContext } from "components/contexts";
import { Widget } from "components/elements/mui";
import { getSelectedPowerPlant, useAppSelector } from "store";

export const ForecastDateFilters = ({
  data,
}: {
  data: GetForecastResponsePrepared["daily"];
}) => {
  const { date, setDate } = useFiltersContext();

  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const timezone = tz(selectedPowerPlant?.timezone || "Europe/Warsaw");

  return (
    <Widget
      size={{ xs: 12 }}
      content={
        <Grid2 container spacing={2} direction={"column"} flexWrap={"nowrap"}>
          <Grid2
            container
            spacing={2.5}
            sx={{
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              overflowX: "auto",
              scrollbarGutter: "stable",
            }}
          >
            {data.map((day) => {
              const isSelected = date.day.getTime() === day.date.getTime();
              return (
                <Grid2
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      fontSize: 13,
                      pb: 1,
                    }}
                  >
                    {format(day.date, "EEEEE").toLocaleUpperCase()}
                  </Typography>
                  <Chip
                    key={day.date.getTime()}
                    variant="outlined"
                    onClick={() =>
                      setDate((values) => ({
                        ...values,
                        day: day.date,
                      }))
                    }
                    label={
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: 15,
                        }}
                      >
                        {format(day.date, "d")}
                      </Typography>
                    }
                    sx={{
                      ".MuiChip-label": { display: "flex" },
                      border: "none",
                      color:
                        isToday(day.date, { in: timezone }) && !isSelected
                          ? lightBlue[500]
                          : isSelected
                            ? "black"
                            : "white",
                      backgroundColor: isSelected
                        ? isToday(day.date, { in: timezone })
                          ? lightBlue[500]
                          : "white"
                        : "transparent",
                    }}
                  />
                </Grid2>
              );
            })}
          </Grid2>
          <Typography
            variant="h6"
            sx={{
              mx: "auto",
              fontWeight: 400,
              fontSize: 15,
              textTransform: "capitalize",
            }}
          >
            {format(date.day, "EEEE, PPP")}
          </Typography>
        </Grid2>
      }
    />
  );
};
