import { Paper, Typography } from "@mui/material";

import { EnergyType, PreparedRecord } from "api";
import { useFormatMessage } from "lang";

export const EnergyChartTooltip = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>({
  active,
  payload,
  formatLabel,
  formatValue,
  isPower,
}: {
  active?: boolean;
  payload?: {
    dataKey: SelectedMetrics;
    payload: PreparedRecord<SelectedMetrics>;
    value: number;
    color: string;
    stroke: string;
  }[];
  formatLabel: (date: Date, period?: number) => string;
  formatValue: (value: number) => string;
  isPower?: boolean;
}) => {
  const formatMessage = useFormatMessage();

  if (!active || !payload || !payload.length) return null;

  return (
    <Paper
      elevation={0}
      sx={{
        m: 1,
        border: "solid",
        borderWidth: 2,
        borderColor: "divider",
        backgroundColor: "rgba(18, 18, 18, 0.8)",
        table: { borderSpacing: 0 },
        thead: {
          td: {
            px: 1.5,
            py: 0.75,
            borderBottom: "solid",
            borderColor: "divider",
          },
        },
        tbody: {
          "tr:first-child": { td: { paddingTop: 1.5 } },
          "tr:last-child": { td: { paddingBottom: 1.5 } },
          tr: {
            "td:first-child": { paddingLeft: 1.5 },
            "td:last-child": { paddingRight: 1.5 },
            td: {
              paddingRight: "7px",
              paddingBottom: "10px",
            },
          },
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <td colSpan={3}>
              <Typography>
                {formatLabel(
                  payload[0].payload.date,
                  payload[0].payload.period,
                )}
              </Typography>
            </td>
          </tr>
        </thead>
        <tbody>
          {payload.map((series) => (
            <tr key={series.dataKey}>
              <td>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 2,
                    backgroundColor: series.color,
                  }}
                />
              </td>
              <td>
                <Typography fontWeight="light">
                  {`${formatMessage(series.dataKey)} ${isPower ? `(${formatMessage("power").toLocaleLowerCase()})` : ""}`}
                </Typography>
              </td>
              <td>
                <Typography>{formatValue(series.value)}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Paper>
  );
};
