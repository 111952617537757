import { Box, Grid2, Typography } from "@mui/material";
import { green, grey, red, yellow } from "@mui/material/colors";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";

import { PowerPlantStatus } from "api";
import { useFormatMessage } from "lang";
import { getLiveData, getSelectedPowerPlant, useAppSelector } from "store";

export const Status = ({
  setBelowStatus,
}: {
  setBelowStatus: (value: boolean) => void;
}) => {
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const liveData = useAppSelector(getLiveData);

  const ref = useRef<HTMLDivElement>(null);
  const [showLastUpdate, setShowLastUpdate] = useState(false);

  const status =
    !liveData || selectedPowerPlant?.offline ? "offline" : liveData.status;
  const statusColorMap: Record<PowerPlantStatus | "offline", string> = {
    producing: green[600],
    consuming: yellow[600],
    idle: grey[600],
    offline: red[600],
  };

  useEffect(() => {
    const observer = () => {
      const element = ref.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      setBelowStatus(rect.top <= 40);
    };

    window.addEventListener("scroll", observer);
    return () => window.removeEventListener("scroll", observer);
  }, [setBelowStatus]);

  useEffect(() => {
    if (status === "offline") setShowLastUpdate(true);
    else setShowLastUpdate(false);
  }, [status]);

  return (
    <Grid2
      ref={ref}
      container
      direction={"column"}
      spacing={1}
      justifyContent={"center"}
      alignItems={"center"}
      pb={4}
    >
      <Grid2
        container
        gap={1}
        sx={{
          backgroundColor: "#1F1F1F",
          px: 3,
          borderRadius: 5,
          alignItems: "center",
        }}
        onClick={() => setShowLastUpdate(!showLastUpdate)}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: 2,
            backgroundColor: statusColorMap[status as PowerPlantStatus],
          }}
        />
        <Typography color={grey[600]} fontSize={14} fontWeight={700}>
          {formatMessage(`status.${status}`)}
        </Typography>
      </Grid2>
      {showLastUpdate && liveData?.date && (
        <Grid2 container direction={"column"} alignItems={"center"} gap={0}>
          <Grid2
            container
            gap={1}
            sx={{
              backgroundColor: "#1F1F1F",
              px: 3,
              borderRadius: 5,
              alignItems: "center",
            }}
          >
            <Typography color={grey[600]} fontSize={14} fontWeight={700}>
              {formatMessage("lastUpdate")}:
            </Typography>
            <Typography color={grey[600]} fontSize={14} fontWeight={700}>
              {format(liveData.date, "P")}
            </Typography>
            <Typography color={grey[500]} fontSize={14} fontWeight={700}>
              {format(liveData.date, "pp")}
            </Typography>
          </Grid2>
        </Grid2>
      )}
    </Grid2>
  );
};
