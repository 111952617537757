import { tz } from "@date-fns/tz";
import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";

import {
  EnergyType,
  GetRecordsResponse,
  HelperFunctionsChartRecords,
  PowerPlant,
  PreparedRecord,
} from "api";
import { formatEnergy, formatPower } from "utils/energy";

export const createHelperFunctionsGrouping = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>(
  powerPlant?: PowerPlant,
): HelperFunctionsChartRecords<SelectedMetrics> => {
  const timezone = tz(powerPlant?.timezone || "Europe/Warsaw");
  const startDate = powerPlant?.startDate
    ? new Date(powerPlant.startDate)
    : new Date();

  const helpers: HelperFunctionsChartRecords<SelectedMetrics> = {
    day: {
      start: (date: Date) =>
        startOfDay(date, {
          in: timezone,
        }),
      end: (date: Date) =>
        endOfDay(date, {
          in: timezone,
        }),
      formatXAxis: (date: Date) => {
        const dateString = format(date, "HH:mm");
        return dateString === "00:00" || dateString === "23:55"
          ? ""
          : dateString;
      },
      formatXTooltip: (date: Date, period?: number) =>
        period
          ? `${format(date, "HH:mm")} - ${format(addMilliseconds(date, period), "HH:mm")}`
          : format(date, "HH:mm"),
      formatYAxis: (value: number) => formatPower(value).formatted,
      formatYTooltip: (value: number) => formatPower(value).formatted,
      formatDateType: (date: Date) => format(date, "P"),
      chartOptions: {
        scaleType: "time", // should be auto, but there's some issue with generated ticks
        seriesType: "line",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>, date: Date) =>
        generateEmptyValuesIfMissing(
          prepareData(data, true),
          helpers.day.start(date),
          helpers.day.end(date),
          (date: Date) => addMinutes(date, 5),
        ),
      generateTicks: (date) =>
        generateTicks(
          helpers.day.start(date),
          helpers.day.end(date),
          (date: Date) => addHours(date, 3),
        ),
    },
    month: {
      start: (date: Date) =>
        startOfMonth(date, {
          in: timezone,
        }),
      end: (date: Date) =>
        endOfMonth(date, {
          in: timezone,
        }),
      formatXAxis: (date: Date) => format(date, "d"),
      formatXTooltip: (date: Date) => format(date, "PPP"),
      formatYAxis: (value: number) => formatEnergy(value).formatted,
      formatYTooltip: (value: number) => formatEnergy(value).formatted,
      formatDateType: (date: Date) => format(date, "M.y"),
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>, date: Date) =>
        generateEmptyValuesIfMissing(
          prepareData(data),
          helpers.month.start(date),
          helpers.month.end(date),
          (date: Date) => addDays(date, 1),
        ),
      generateTicks: (date) =>
        generateTicks(
          helpers.month.start(date),
          helpers.month.end(date),
          (date: Date) => addDays(date, 1),
        ),
    },
    year: {
      start: (date: Date) =>
        startOfYear(date, {
          in: timezone,
        }),
      end: (date: Date) =>
        endOfYear(date, {
          in: timezone,
        }),
      formatXAxis: (date: Date) => format(date, "M"),
      formatXTooltip: (date: Date) => format(date, "LLLL Y"),
      formatYAxis: (value: number) => formatEnergy(value).formatted,
      formatYTooltip: (value: number) => formatEnergy(value).formatted,
      formatDateType: (date: Date) => format(date, "y"),
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>, date: Date) =>
        generateEmptyValuesIfMissing(
          prepareData(data),
          helpers.year.start(date),
          helpers.year.end(date),
          (date: Date) => addMonths(date, 1),
        ),
      generateTicks: (date) =>
        generateTicks(
          helpers.year.start(date),
          helpers.year.end(date),
          (date: Date) => addMonths(date, 1),
        ),
    },
    total: {
      start: () =>
        startOfYear(startDate, {
          in: timezone,
        }),
      end: () =>
        startOfYear(addYears(new Date(), 1), {
          in: timezone,
        }),
      formatXAxis: (date: Date) => format(date, "y"),
      formatXTooltip: (date: Date) => format(date, "y"),
      formatYAxis: (value: number) => formatEnergy(value).formatted,
      formatYTooltip: (value: number) => formatEnergy(value).formatted,
      chartOptions: {
        scaleType: "auto",
        seriesType: "bar",
      },
      prepareData: (data: GetRecordsResponse<SelectedMetrics>) =>
        prepareData(data),
      generateTicks: (date) =>
        generateTicks(
          helpers.total.start(date),
          helpers.total.end(date),
          (date: Date) => addYears(date, 1),
        ),
    },
  };
  return helpers;
};

const prepareData = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>(
  data: GetRecordsResponse<SelectedMetrics>,
  calculatePower = false,
): PreparedRecord<SelectedMetrics>[] => {
  const sortedData = data.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  return sortedData.map((record) => {
    const updatedMetrics = Object.keys(record).reduce(
      (acc, field) => {
        if (field === "date" || field === "period") return acc;
        const currentMetric = record[field as SelectedMetrics];

        if (
          calculatePower &&
          typeof currentMetric === "object" &&
          record.period
        )
          acc[field as SelectedMetrics] =
            (currentMetric.calculated / record.period) * 1000 * 60 * 60;
        else if (typeof currentMetric === "object")
          acc[field as SelectedMetrics] = currentMetric.calculated;
        else acc[field as SelectedMetrics] = 0;

        return acc;
      },
      {} as {
        [K in SelectedMetrics]: number;
      },
    );

    return {
      ...record,
      ...updatedMetrics,
      date: new Date(record.date),
    };
  }) as PreparedRecord<SelectedMetrics>[];
};

const generateEmptyValuesIfMissing = <
  SelectedMetrics extends EnergyType =
    | "energyConsumed"
    | "energyProduced"
    | "inductiveEnergyConsumed"
    | "capacitiveEnergyProduced"
    | "inductiveEnergyProduced"
    | "capacitiveEnergyConsumed",
>(
  data: PreparedRecord<SelectedMetrics>[],
  start: Date,
  end: Date,
  intervalFunction: (date: Date) => Date,
): PreparedRecord<SelectedMetrics>[] => {
  const metrics = Object.keys(data[0] || {}).filter(
    (key) => key !== "date" && key !== "period",
  ) as SelectedMetrics[];

  let current = start;

  while (current <= end) {
    const nextDate = intervalFunction(current);
    const existingRecord = data.some(
      (record) => record.date >= current && record.date < nextDate,
    );

    if (!existingRecord) {
      const emptyRecord = metrics.reduce(
        (acc, metric) => {
          acc[metric] = undefined;
          return acc;
        },
        {} as { [K in SelectedMetrics]: number | undefined },
      );

      data.push({
        ...emptyRecord,
        date: current,
        period: nextDate.getTime() - current.getTime(),
      });
    }

    current = nextDate;
  }

  return data.sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const generateTicks = (
  start: Date,
  end: Date,
  intervalFunction: (date: Date) => Date,
): number[] => {
  const ticks = [];
  let current = start;

  while (current <= end) {
    ticks.push(current.getTime());
    current = intervalFunction(current);
  }
  return ticks;
};
