import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppLayoutContext } from "components/contexts";
import { useFormatMessage } from "lang";
import { useIsPageActive } from "utils/hooks/useIsPageActive";

import { mainListItems, secondaryListItems } from "../../const";

export const SidebarContent = () => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const { setSidebarOpen } = useAppLayoutContext();
  const isActive = useIsPageActive();

  const onClick = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setSidebarOpen(false);
    navigate(newValue);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 2, justifyContent: "space-between" }}>
      <List dense>
        {[...mainListItems, ...secondaryListItems].map((item) => (
          <ListItem key={item.value} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={(e) => onClick(e, item.value)}
              selected={isActive(item.value)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={formatMessage(item.label)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* <List dense>
        {bottomListItems.map((item) => (
          <ListItem key={item.value} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={(e) => onClick(e, item.value)}
              selected={isActive(item.value)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={formatMessage(item.label)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Stack>
  );
};
