import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { axiosInstance, FORECAST } from "api";
import { getSelectedPowerPlant, useAppSelector } from "store";
import { calculateDay } from "utils/forecast";

import {
  GetForecastRequest,
  GetForecastResponse,
  GetForecastResponsePrepared,
} from "./types";

export const useGetForecast = ({
  meterId: meterIdProp,
  options,
  request,
}: {
  meterId?: string;
  request?: GetForecastRequest;
  options?: Partial<UseQueryOptions<GetForecastResponsePrepared>>;
}) => {
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const meterId = meterIdProp || selectedPowerPlant?.meterId;
  const timezone = selectedPowerPlant?.timezone || "Europe/Warsaw";

  return useQuery<GetForecastResponsePrepared>({
    queryKey: ["getForecast", meterId, JSON.stringify(request), timezone],
    queryFn: async () => {
      const data = await axiosInstance.get<GetForecastResponse>(
        FORECAST.FORECAST(meterId!),
        {
          params: request,
        },
      );

      return {
        ...data.data,
        minutely15: data.data.minutely15.map((record) => ({
          ...record,
          date: new Date(record.date),
        })),
        daily: calculateDay(data.data, timezone),
      };
    },
    enabled: !!meterId,
    ...options,
  });
};
