import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AppLayout } from "components/layouts";
import { ForecastPage, LoadingPage, RecordsPage, StatisticsPage } from "routes";
import {
  ANY_OTHER,
  RECORDS,
  DEFAULT,
  STATISTICS,
  FORECAST,
} from "routes/consts";

export const PrivateRoutes = () => (
  <Suspense fallback={<LoadingPage />}>
    <Routes>
      <Route path={DEFAULT} element={<AppLayout />}>
        <Route path={FORECAST} element={<ForecastPage />} />
        <Route path={RECORDS} element={<RecordsPage />} />
        <Route path={STATISTICS} element={<StatisticsPage />} />
      </Route>
      <Route path={ANY_OTHER} element={<Navigate to={DEFAULT} replace />} />
    </Routes>
  </Suspense>
);
