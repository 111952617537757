import { CircularProgress, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import {
  Area,
  Bar,
  Brush,
  ComposedChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./styles.scss";

import { useGetRecords } from "api";
import { useFiltersContext } from "components/contexts";
import { Widget, WidgetContent } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";
import { formatEnergy } from "utils/energy";
import { useToday } from "utils/hooks/useToday";

import { EnergyChartTooltip } from "./components";
import { createHelperFunctionsGrouping } from "./utils";

export const EnergyChart = ({
  title,
  url,
  showValue,
  dashboard,
}: {
  title?: string;
  url?: string;
  showValue?: boolean;
  dashboard?: boolean;
}) => {
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const {
    date,
    dateType: dateTypeContext,
    energyType: energyTypeContext,
    brush,
    setBrush,
  } = useFiltersContext();
  const today = useToday(selectedPowerPlant?.timezone || "Europe/Warsaw");
  const [partialChart, setPartialChart] = useState(false);

  const dateType = dateTypeContext[0];
  const energyType = energyTypeContext[0];

  const helperFunctions = useMemo(
    () => createHelperFunctionsGrouping(selectedPowerPlant),
    [selectedPowerPlant],
  );

  const startDate = useMemo(
    () => helperFunctions[dateType].start(dashboard ? today : date[dateType]),
    [helperFunctions, dateType, dashboard, today, date],
  );
  const endDate = useMemo(
    () => helperFunctions[dateType].end(dashboard ? today : date[dateType]),
    [helperFunctions, dateType, dashboard, today, date],
  );

  const isTodaySelected = useMemo(
    () =>
      today.getTime() >= startDate.getTime() &&
      today.getTime() <= endDate.getTime(),
    [startDate, endDate, today],
  );

  const { data, isLoading } = useGetRecords({
    request: {
      startDate: startDate!.toISOString(),
      endDate: endDate!.toISOString(),
    },
    options: {
      refetchInterval:
        isTodaySelected && selectedPowerPlant?.recordSaveInterval,
      refetchOnWindowFocus: isTodaySelected,
      staleTime: isTodaySelected
        ? selectedPowerPlant?.liveDataInterval
        : selectedPowerPlant?.recordSaveInterval,
      enabled: !!(startDate && endDate),
    },
  });

  const preparedData = useMemo(
    () =>
      data
        ? helperFunctions[dateType].prepareData(data, date[dateType])
        : undefined,
    [data, helperFunctions, date, dateType],
  );
  const statisticValue = useMemo(
    () =>
      showValue && data && data.length > 0
        ? formatEnergy(
            data[data.length - 1][energyType].raw +
              data[data.length - 1][energyType].calculated -
              (dateType === "total" ? 0 : data[0][energyType].raw),
          )
        : undefined,
    [data, dateType, energyType, showValue],
  );

  const noData = useMemo(() => !data || data.length === 0, [data]);
  return (
    <>
      {showValue && statisticValue && (
        <Widget
          title={`${formatMessage(energyType)} (${
            helperFunctions[dateType].formatDateType
              ? helperFunctions[dateType].formatDateType(date[dateType])
              : formatMessage(`grouping.${dateType}`)
          })`}
          icon={"analytics"}
          size={{ xs: 12 }}
          content={
            <WidgetContent
              value={statisticValue.value}
              unit={statisticValue.unit}
            />
          }
          url={url}
        />
      )}
      <Widget
        title={title ?? formatMessage(`chart`)}
        icon={dateType === "day" ? "show_chart" : "bar_chart"}
        url={url}
        onlyHeaderClickable
        size={{ xs: 12 }}
        content={
          <ResponsiveContainer height={300} style={{ position: "relative" }}>
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <ComposedChart
                data={preparedData}
                margin={{
                  top: 20,
                  right: 10,
                  bottom: 0,
                  left: noData ? -50 : 0,
                }}
              >
                {noData || !preparedData ? (
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    fill={theme.palette.text.disabled}
                  >
                    {formatMessage("chart.noData")}
                  </text>
                ) : (
                  <>
                    <XAxis
                      dataKey="date"
                      scale={
                        partialChart
                          ? "auto"
                          : helperFunctions[dateType].chartOptions.scaleType
                      }
                      ticks={
                        partialChart
                          ? undefined
                          : helperFunctions[dateType].generateTicks(
                              date[dateType],
                            )
                      }
                      tickFormatter={(value) =>
                        helperFunctions[dateType].formatXAxis(value)
                      }
                      minTickGap={partialChart ? 66 : undefined}
                      min={startDate.getTime()}
                      max={endDate.getTime()}
                      tick={{
                        fill: theme.palette.text.primary,
                        fontSize: 12,
                        fontFamily: theme.typography.fontFamily,
                      }}
                      axisLine={{
                        stroke: theme.palette.divider,
                        strokeWidth: 1,
                      }}
                    />
                    <YAxis
                      dataKey={energyType}
                      tickFormatter={(value) =>
                        helperFunctions[dateType].formatYAxis(value)
                      }
                      tick={{
                        fill: theme.palette.text.primary,
                        fontSize: 12,
                        fontFamily: theme.typography.fontFamily,
                      }}
                      axisLine={{
                        stroke: theme.palette.divider,
                        strokeWidth: 1,
                      }}
                    />
                    <Tooltip
                      content={
                        <EnergyChartTooltip
                          formatLabel={helperFunctions[dateType].formatXTooltip}
                          formatValue={helperFunctions[dateType].formatYTooltip}
                          isPower={dateType === "day"}
                        />
                      }
                      cursor={{
                        strokeDasharray: "5 5",
                        stroke: theme.palette.divider,
                        strokeWidth: 2,
                      }}
                      position={{ y: 0 }}
                    />
                    <Bar
                      dataKey={energyType}
                      fill={theme.palette.secondary.main}
                      activeBar={<Rectangle fill={"#413ea0"} />}
                      hide={
                        helperFunctions[dateType].chartOptions.seriesType !==
                        "bar"
                      }
                    />
                    <Area
                      type="monotone"
                      dataKey={energyType}
                      stroke={theme.palette.secondary.main}
                      strokeWidth={2}
                      fill="url(#colorGradient)"
                      hide={
                        helperFunctions[dateType].chartOptions.seriesType !==
                        "line"
                      }
                    />
                    {!dashboard && dateType === "day" && (
                      <Brush
                        dataKey="date"
                        tickFormatter={(value) =>
                          helperFunctions[dateType].formatXAxis(value)
                        }
                        height={36}
                        travellerWidth={20}
                        fill={
                          theme.palette.mode === "dark" ? "#121212" : "#fff"
                        }
                        stroke={theme.palette.divider}
                        startIndex={brush.startIndex}
                        endIndex={brush.endIndex}
                        onChange={(newIndex: {
                          startIndex?: number;
                          endIndex?: number;
                        }) => {
                          setBrush(newIndex);
                          if (
                            newIndex.endIndex !== undefined &&
                            newIndex.startIndex !== undefined &&
                            newIndex.endIndex - newIndex.startIndex <
                              preparedData.length - 1
                          )
                            setPartialChart(true);
                          else setPartialChart(false);
                        }}
                      />
                    )}
                    <defs>
                      <linearGradient
                        id="colorGradient"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor={theme.palette.secondary.main}
                          stopOpacity={0.9}
                        />
                        <stop
                          offset="70%"
                          stopColor={theme.palette.secondary.main}
                          stopOpacity={0.3}
                        />
                        <stop
                          offset="95%"
                          stopColor={theme.palette.secondary.main}
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                  </>
                )}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        }
      />
    </>
  );
};
