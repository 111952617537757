import { formatInTimeZone, fromZonedTime } from "date-fns-tz";

import { GetForecastResponse } from "api";

/**
 * Calculate forecasr data grouped by day.
 */
export const calculateDay = (
  data: GetForecastResponse,
  timezone: string,
): {
  date: Date;
  energy: number;
  temperature: { min: number; max: number };
}[] =>
  Object.values(
    data.minutely15.reduce(
      (
        acc: {
          [date: string]: {
            date: Date;
            energy: number;
            temperature: { min: number; max: number };
          };
        },
        { date, energy, temperature },
      ) => {
        const localDay = formatInTimeZone(date, timezone, "yyyy-MM-dd");
        if (!acc[localDay]) {
          acc[localDay] = {
            date: fromZonedTime(localDay, timezone),
            energy: 0,
            temperature: { min: Infinity, max: -Infinity },
          };
        }
        acc[localDay].energy += energy;
        acc[localDay].temperature.min = Math.min(
          acc[localDay].temperature.min,
          temperature,
        );
        acc[localDay].temperature.max = Math.max(
          acc[localDay].temperature.max,
          temperature,
        );
        return acc;
      },
      {},
    ),
  ).filter((day) => day.energy > 0);
