export const AUTH = {
  JOIN: "/auth/join",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  REFRESH_TOKEN: "/auth/refresh-token",
  VALIDATE_JOIN_TOKEN: "/auth/validate/join-token",
};

export const FORECAST = {
  FORECAST: (powerPlantId: string) => `/forecast/${powerPlantId}`,
};

export const POWER_PLANT = {
  POWER_PLANT: (powerPlantId: string) => `/power-plant/${powerPlantId}`,
  POWER_PLANT_LIVE: (powerPlantId: string) =>
    `/power-plant/${powerPlantId}/live`,
  POWER_PLANT_PERMISSIONS: (powerPlantId: string) =>
    `/power-plant/${powerPlantId}/permissions`,
};

export const RECORDS = {
  RECORDS: (powerPlantId: string) => `/records/${powerPlantId}`,
};

export const USER = {
  USER: "/user",
};
