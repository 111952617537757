import { tz } from "@date-fns/tz";
import { startOfDay } from "date-fns";

import { GetForecastResponsePrepared } from "api";
import { Widget, WidgetContent } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";
import { formatEnergy } from "utils/energy";

export const ForecastEnergy = ({
  data,
}: {
  data: GetForecastResponsePrepared;
}) => {
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);

  const energy = formatEnergy(
    data.daily.find(
      (d) =>
        d.date.getTime() ===
        startOfDay(new Date(), {
          in: tz(selectedPowerPlant?.timezone || "Europe/Warsaw"),
        }).getTime(),
    )?.energy || 0,
  );

  return (
    <Widget
      title={formatMessage("predictedProduction")}
      size={{
        xs: 6,
      }}
      content={<WidgetContent value={energy.value} unit={energy.unit} />}
    />
  );
};
