import { ChartDateType, HelperFunctionsChartDateType } from "api/queries/types";

export const energyTypeList = [
  "energyConsumed",
  "energyProduced",
  "inductiveEnergyConsumed",
  "capacitiveEnergyProduced",
  "inductiveEnergyProduced",
  "capacitiveEnergyConsumed",
] as const;

export type EnergyType = (typeof energyTypeList)[number];

export type GetRecordsRequest<SelectedMetrics extends EnergyType> = {
  startDate: string;
  endDate: string;
  timezone?: string;
  metrics?: SelectedMetrics[];
};

export type GetRecordsResponse<SelectedMetrics extends EnergyType> = (Record<
  SelectedMetrics,
  { calculated: number; raw: number }
> & {
  date: string;
  period?: number;
})[];

export type PreparedRecord<SelectedMetrics extends EnergyType> = {
  [K in SelectedMetrics]: number | undefined;
} & {
  date: Date;
  period?: number;
};

export type HelperFunctionsChartRecords<SelectedMetrics extends EnergyType> = {
  [DateType in ChartDateType]: HelperFunctionsChartDateTypeRecords<SelectedMetrics>;
};

export type HelperFunctionsChartDateTypeRecords<
  SelectedMetrics extends EnergyType,
> = HelperFunctionsChartDateType & {
  prepareData: (
    data: GetRecordsResponse<SelectedMetrics>,
    date: Date,
  ) => PreparedRecord<SelectedMetrics>[];
};

export type HelperFunctionsDatePicker = {
  [DateType in ChartDateType]: {
    formatDateType?: (date: Date) => string;
    datePickerOptions: {
      views: ("year" | "month" | "day")[];
      openTo?: "year" | "month" | "day";
      hidden: boolean;
    };
    prevDate?: (date: Date) => Date;
    nextDate?: (date: Date) => Date;
    prevDisabled?: (date: Date) => boolean;
    nextDisabled?: (date: Date) => boolean;
  };
};
