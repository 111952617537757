import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import PullToRefresh from "react-simple-pull-to-refresh";
import "styles/index.scss";

import { POWER_PLANT, RECORDS } from "api";
import { DashboardPage } from "routes";
import { getSelectedPowerPlant, useAppSelector } from "store";
import { isPWA } from "utils/detectDevice";

import { Navbar } from "./Navbar";
import { Status } from "./Navbar/components";

export const MainContent = () => {
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const queryClient = useQueryClient();

  const [belowStatus, setBelowStatus] = useState(false);

  return (
    <>
      <Navbar belowStatus={belowStatus} />
      <PullToRefresh
        isPullable={isPWA()}
        onRefresh={() =>
          Promise.all([
            queryClient
              .invalidateQueries({
                queryKey: [
                  POWER_PLANT.POWER_PLANT_LIVE(
                    selectedPowerPlant?.meterId || "",
                  ),
                ],
              })
              .then(() =>
                queryClient.refetchQueries({
                  queryKey: [
                    POWER_PLANT.POWER_PLANT_LIVE(
                      selectedPowerPlant?.meterId || "",
                    ),
                  ],
                  stale: true,
                }),
              ),
            queryClient.refetchQueries({
              queryKey: [RECORDS.RECORDS(selectedPowerPlant?.meterId || "")],
              exact: false,
            }),
          ]).then(() => true)
        }
        pullingContent={<></>}
      >
        <>
          <Status setBelowStatus={setBelowStatus} />
          <DashboardPage />
        </>
      </PullToRefresh>
    </>
  );
};
