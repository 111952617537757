import { Box, Grid2, Typography } from "@mui/material";
import { green, grey, red, yellow } from "@mui/material/colors";

import { PowerPlantStatus } from "api";
import { getLiveData, getSelectedPowerPlant, useAppSelector } from "store";

import { LiveData } from "./components";

export const Navbar = ({ belowStatus }: { belowStatus: boolean }) => {
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const liveData = useAppSelector(getLiveData);

  const status =
    !liveData || selectedPowerPlant?.offline ? "offline" : liveData.status;
  const statusColorMap: Record<PowerPlantStatus | "offline", string> = {
    producing: green[600],
    consuming: yellow[600],
    idle: grey[600],
    offline: red[600],
  };

  return (
    <Grid2
      container
      direction={"column"}
      className={
        "bg-black/70 backdrop-blur gap-y-2 transition-padding duration-300"
      }
      position={"fixed"}
      top={0}
      pt={belowStatus ? 2 : 6}
      pb={belowStatus ? 2 : 0}
      zIndex={1000}
      width={"100%"}
    >
      <Grid2
        container
        direction={"column"}
        spacing={0}
        alignItems={"center"}
        width={"100%"}
        sx={{
          transform: "translateX(8px)",
        }}
      >
        <Grid2
          container
          gap={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography
            align="center"
            variant="h5"
            component="div"
            color="primary"
            fontSize={22}
            fontWeight={900}
          >
            {selectedPowerPlant?.name || "Power Plant"}
          </Typography>
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: 2,
              backgroundColor: statusColorMap[status as PowerPlantStatus],
              visibility: belowStatus ? "visible" : "hidden",
            }}
          />
        </Grid2>
      </Grid2>
      <LiveData />
    </Grid2>
  );
};
