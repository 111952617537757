import { ScaleType } from "recharts/types/util/types";

import { MessageKey } from "lang";

export type ValidationError = {
  field: string;
  message: MessageKey;
};

export type ErrorResponse = { message: MessageKey } | ValidationError[];

// Charts

export const chartDate = ["day", "month", "year", "total"] as const;

export type ChartDateType = (typeof chartDate)[number];

export type HelperFunctionsChartDateType = {
  start: (date: Date) => Date;
  end: (date: Date) => Date;
  generateTicks: (date: Date) => number[];
  formatXAxis: (date: Date) => string;
  formatXTooltip: (date: Date, period?: number) => string;
  formatYAxis: (value: number) => string;
  formatYTooltip: (value: number) => string;
  formatDateType?: (date: Date) => string;
  chartOptions: {
    scaleType: ScaleType;
    seriesType: "line" | "bar";
  };
};
