import { useState } from "react";

import { ChartDateType, EnergyType } from "api";
import { useStateAddon } from "utils/hooks/useStateAddon";

import { FiltersContext } from "./FiltersContext";

export const FiltersContextProvider: React.FC<{
  initialState?: {
    date?: Record<ChartDateType, Date>;
    dateType?: ChartDateType[];
    energyType?: EnergyType[];
  };
  children: React.ReactNode;
}> = ({ initialState, children }) => {
  const [date, setDate] = useStateAddon<Record<ChartDateType, Date>>(
    initialState?.date ?? {
      day: new Date(),
      month: new Date(),
      year: new Date(),
      total: new Date(),
    },
    () => setBrush({ startIndex: undefined, endIndex: undefined }),
  );
  const [dateType, setDateType] = useState<ChartDateType[]>(
    initialState?.dateType ?? ["day"],
  );
  const [energyType, setEnergyType] = useState<EnergyType[]>(
    initialState?.energyType ?? ["energyProduced"],
  );
  const [brush, setBrush] = useState<{
    startIndex?: number;
    endIndex?: number;
  }>({
    startIndex: undefined,
    endIndex: undefined,
  });

  return (
    <FiltersContext.Provider
      value={{
        date,
        setDate,
        dateType,
        setDateType,
        energyType,
        setEnergyType,
        brush,
        setBrush,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
