export type FormatEnergyPowerReturn = {
  value: number;
  originalValue: number;
  unit: string;
  formatted: string;
};

/**
 * Formats the given energy value.
 *
 * @param value - The energy value in kilowatt hours (kWh) to be formatted.
 * @returns The formatted energy value.
 */
export const formatEnergy = (value: number): FormatEnergyPowerReturn =>
  formatEnergyPowerGeneric(value, false);

/**
 * Formats the given power value.
 *
 * @param value - The power value in kilowatts (kW) to be formatted.
 * @returns The formatted power value.
 */
export const formatPower = (value: number): FormatEnergyPowerReturn =>
  formatEnergyPowerGeneric(value, true);

const MEGA = 1000; // in kilo
const GIGA = 1000000; // in kilo

const formatEnergyPowerGeneric = (
  value: number,
  power: boolean,
): FormatEnergyPowerReturn => {
  const unitSuffix = power ? "" : "h";

  if (value < 1) {
    return {
      value: +(value * 1000).toFixed(3),
      originalValue: value,
      unit: `W${unitSuffix}`,
      formatted: `${+(value * 1000).toFixed(3)} W${unitSuffix}`,
    };
  }
  if (value < MEGA) {
    return {
      value: +value.toFixed(3),
      originalValue: value,
      unit: `kW${unitSuffix}`,
      formatted: `${+value.toFixed(3)} kW${unitSuffix}`,
    };
  }
  if (value < GIGA) {
    return {
      value: +(value / MEGA).toFixed(3),
      originalValue: value,
      unit: `MW${unitSuffix}`,
      formatted: `${+(value / MEGA).toFixed(3)} MW${unitSuffix}`,
    };
  }
  return {
    value: +(value / GIGA).toFixed(3),
    originalValue: value,
    unit: `GW${unitSuffix}`,
    formatted: `${+(value / GIGA).toFixed(3)} GW${unitSuffix}`,
  };
};

export const calculateMVVoltage = (
  voltage: number,
  mediumVoltage: number,
): number =>
  +((((voltage * mediumVoltage) / 100) * Math.sqrt(3)) / 1000).toFixed(2);

export const calculateMVCurrent = (
  current: number,
  mediumCurrent: number,
): number => +((current * mediumCurrent) / 5).toFixed(2);

export const calculateLVVoltage = (
  voltage: number,
  mediumVoltage: number | null,
): number =>
  mediumVoltage ? +(voltage * 4).toFixed(2) : +(voltage * 1).toFixed(2);

export const calculateLVCurrent = (
  current: number,
  mediumCurrent: number,
  mediumVoltage: number | null,
): number =>
  mediumVoltage
    ? +((((current * mediumCurrent) / 5) * mediumVoltage) / 400).toFixed(2)
    : +((current * mediumCurrent) / 5).toFixed(2);
