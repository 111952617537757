import { Grid2 } from "@mui/material";

import { useGetForecast } from "api";
import { Widget } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { FORECAST } from "routes/consts";

import { ForecastEnergy } from "./components/ForecastEnergy";
import { ForecastWeather } from "./components/ForecastWeather";

export const Forecast = () => {
  const formatMessage = useFormatMessage();
  const FIVE_MINUTES = 5 * 60 * 1000;

  const { data } = useGetForecast({
    request: {
      limited: true,
    },
    options: {
      refetchInterval: FIVE_MINUTES,
      refetchOnWindowFocus: true,
      staleTime: FIVE_MINUTES,
    },
  });

  if (!data) return null;

  return (
    <Widget
      title={formatMessage("pageTitle.forecast")}
      icon={"insights"}
      content={
        <Grid2 container spacing={2}>
          <ForecastWeather data={data} />
          <ForecastEnergy data={data} />
        </Grid2>
      }
      url={FORECAST}
      size={{ xs: 12 }}
    />
  );
};
