import {
  Card,
  CardContent,
  Grid2,
  Typography,
  Icon,
  Collapse,
  Box,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export type WidgetProps = {
  size?: { xs?: number; sm?: number; md?: number };
  title?: string;
  content: JSX.Element;
  icon?: string;
  url?: string;
  onlyHeaderClickable?: boolean;
  expandable?: boolean;
  expandedByDefault?: boolean;
  customOnClick?: () => void;
  customOnClickIcon?: string;
  styles?: {
    cardContent?: React.CSSProperties;
  };
};

export const Widget = forwardRef<HTMLDivElement, WidgetProps>(
  (
    {
      size = { xs: 6 },
      title,
      content,
      icon,
      url,
      onlyHeaderClickable,
      expandable,
      expandedByDefault = true,
      customOnClick,
      customOnClickIcon,
      styles,
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState<boolean>(expandedByDefault);
    const actionIcon = customOnClickIcon || "open_in_full";

    return (
      <Grid2
        size={size}
        onClick={() => {
          if (!onlyHeaderClickable) {
            if (url) navigate(url);
            else if (expandable) setExpanded(!expanded);
            else if (customOnClick) customOnClick();
          }
        }}
      >
        <Card variant="outlined">
          <CardContent
            ref={ref}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              rowGap: 0,
              height: "100%",
              ...styles?.cardContent,
            }}
          >
            <Grid2
              container
              color={grey[700]}
              display={
                title || icon || url || expandable || customOnClick
                  ? "flex"
                  : "none"
              }
              alignItems={"center"}
              justifyContent={"space-between"}
              onClick={() => {
                if (onlyHeaderClickable) {
                  if (url) navigate(url);
                  else if (expandable) setExpanded(!expanded);
                  else if (customOnClick) customOnClick();
                }
              }}
            >
              <Grid2 container spacing={1} alignItems={"center"}>
                <Icon
                  sx={{
                    fontSize: 18,
                    display: icon ? "block" : "none",
                  }}
                >
                  {icon}
                </Icon>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 13,
                    lineHeight: "20px",
                    fontWeight: 500,
                    display: title ? "block" : "none",
                  }}
                >
                  {title}
                </Typography>
              </Grid2>
              <Icon
                sx={{
                  fontSize: expandable ? 18 : 14,
                  display:
                    url || expandable || customOnClick ? "block" : "none",
                }}
              >
                {expandable
                  ? expanded
                    ? "expand_less"
                    : "expand_more"
                  : actionIcon}
              </Icon>
            </Grid2>
            <Collapse in={expanded}>
              <Box paddingTop={title ? 1 : 0}>{content}</Box>
            </Collapse>
          </CardContent>
        </Card>
      </Grid2>
    );
  },
);
