import {
  Grid2,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMemo } from "react";

import { DailyPrepared, ForecastParameter, HourlyPrepared } from "api";
import { useFormatMessage } from "lang";

import { useParamsUtils } from "../utils";

export const ForecastWidgetContent = ({
  selectedParam,
  setSelectedParam,
  currentDaily,
  currentHourly,
}: {
  selectedParam: ForecastParameter;
  setSelectedParam: React.Dispatch<React.SetStateAction<ForecastParameter>>;
  currentDaily?: DailyPrepared;
  currentHourly?: HourlyPrepared;
}) => {
  const formatMessage = useFormatMessage();
  const paramsUtils = useParamsUtils();

  const component = useMemo(
    () => paramsUtils[selectedParam].component(currentDaily, currentHourly),
    [currentDaily, currentHourly, paramsUtils, selectedParam],
  );

  return (
    <Grid2 container size={12} spacing={1} justifyContent={"space-between"}>
      {component}
      <Grid2
        container
        direction={"column"}
        spacing={0}
        justifyContent="flex-start"
      >
        <Select
          value={selectedParam}
          onChange={(event: SelectChangeEvent) =>
            setSelectedParam(event.target.value as ForecastParameter)
          }
          renderValue={() => (
            <Icon
              sx={{
                fontSize: 18,
              }}
            >
              {paramsUtils[selectedParam].icon}
            </Icon>
          )}
          sx={{
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              gap: 4,
              py: 2.5,
            },
          }}
        >
          {Object.entries(paramsUtils).map(([key, value]) => (
            <MenuItem
              key={key}
              value={key}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                minWidth: 150,
                justifyContent: "space-between",
              }}
            >
              {formatMessage(value.label)}
              <Icon>{value.icon}</Icon>
            </MenuItem>
          ))}
        </Select>
      </Grid2>
    </Grid2>
  );
};
